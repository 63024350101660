import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'

const Headline = styled.h1`
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 2.8rem;
`

const SubHeadline = styled.h2`
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 2.2rem;
`

const Datenschutz = () => (
  <Layout>
    <SEO title="Datenschutz" />
    <div className="grid">
      <div className="grid-contained">
        <Headline>Datenschutzerklärung</Headline>
        <SubHeadline>Benennung der verantwortlichen Stelle</SubHeadline>
        <p>
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Website ist:
        </p>
        <p>
          <span id="s3-t-ansprechpartner">Ruth Nießen</span>
          <br />
          <span id="s3-t-strasse">Kochhannstr. 38</span>
          <br />
          <span id="s3-t-plz">10249</span> <span id="s3-t-ort">Berlin</span>
        </p>
        <p>
          Die verantwortliche Stelle entscheidet allein oder gemeinsam mit
          anderen über die Zwecke und Mittel der Verarbeitung von
          personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
        </p>
        <SubHeadline>
          Widerruf Ihrer Einwilligung zur Datenverarbeitung
        </SubHeadline>
        <p>
          Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der
          Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
          Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
          formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum
          Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
        <SubHeadline>
          Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
        </SubHeadline>
        <p>
          Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
          Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen
          ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der
          Sitz unseres Unternehmens befindet. Der folgende Link stellt eine
          Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:{' '}
          <a
            href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
            target="_blank"
          >
            https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
          </a>
          .
        </p>
        <SubHeadline>Recht auf Datenübertragbarkeit</SubHeadline>
        <p>
          Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer
          Einwilligung oder in Erfüllung eines Vertrags automatisiert
          verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die
          Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie
          die direkte Übertragung der Daten an einen anderen Verantwortlichen
          verlangen, erfolgt dies nur, soweit es technisch machbar ist.
        </p>
        <SubHeadline>
          Recht auf Auskunft, Berichtigung, Sperrung, Löschung
        </SubHeadline>
        <p>
          Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen
          das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
          personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den
          Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung,
          Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu
          weiteren Fragen zum Thema personenbezogene Daten können Sie sich
          jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an
          uns wenden.
        </p>
        <SubHeadline>SSL- bzw. TLS-Verschlüsselung</SubHeadline>
        <p>
          Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
          Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere
          Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie
          über diese Website übermitteln, für Dritte nicht mitlesbar. Sie
          erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile
          Ihres Browsers und am Schloss-Symbol in der Browserzeile.
        </p>

        <p>
          <small>
            Quelle: Datenschutz-Konfigurator von{' '}
            <a
              href="http://www.mein-datenschutzbeauftragter.de"
              target="_blank"
            >
              mein-datenschutzbeauftragter.de
            </a>
          </small>
        </p>
      </div>
    </div>
  </Layout>
)

export default Datenschutz
